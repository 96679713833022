import React from 'react';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { TPAComponentsProvider } from 'wix-ui-tpa';
import { st, classes } from './Widget.st.css';
import EmptyState from './EmptyState';
import RuntimeContext, { connect } from '../../../common/components/runtime-context';
import { TabState } from '../../../types/settings';
import { RootState } from '../state';
import CancelConfirmModal from './CancelConfirmModal';
import settingsParams from '../settingsParams';
import SubscriptionsListBass from './SubscriptionsListBass';
import UpmModal from './UpmModal/UpmModal';
import Toast from './Toast/Toast';
import PayNowModal from './PayNowModal/PayNowModal';
import InfiniteScrollProvider from './InfiniteScrollProvider/InfiniteScrollProvider';
import { Experiments } from '../../../Experiments';

type RuntimeProps = ReturnType<typeof mapRuntimeToProps>;

interface WidgetWrapperProps {
  tabState: TabState;
  ppInstalled: boolean;
}

interface ContextParams {
  methods: Partial<any>;
  host: Partial<any>;
  state: RootState;
}

export default function (props: WidgetWrapperProps & ContextParams & RuntimeProps) {
  const { state, methods, host, tabState, ppInstalled } = props;
  const { isMobile, isRTL } = useEnvironment();
  return (
    <TPAComponentsProvider value={{ mobile: isMobile, rtl: isRTL }}>
      <RuntimeContext.Provider value={{ state, actions: methods, host }}>
        <Widget tabState={tabState} ppInstalled={ppInstalled} />
      </RuntimeContext.Provider>
    </TPAComponentsProvider>
  );
}

const WidgetComponent = ({
  tabState,
  ppInstalled,
  subscriptions,
  isUpmModalOpened,
  isPayNowModalOpened,
}: WidgetWrapperProps & RuntimeProps) => {
  const settings = useSettings();
  const { isMobile, isRTL } = useEnvironment();
  const { experiments } = useExperiments();
  const exposeNewFeature = experiments.enabled(Experiments.EXPOSE_NEW_FEATURE);
  return (
    <div
      role="region"
      aria-labelledby="my-subscriptions-title"
      className={st(classes.root, isMobile ? { mobile: isMobile } : undefined)}
      data-hook="MySubscriptions-wrapper"
      dir={isRTL ? 'rtl' : 'ltr'}
    >
      {exposeNewFeature && 'New Feature'}
      {!isMobile && (
        <div className={classes.header}>
          <h1 id="my-subscriptions-title" data-hook="app-page-title">
            {settings.get(settingsParams.pageTitleText)}
          </h1>
          <p data-hook="app-page-description">{settings.get(settingsParams.pageDescriptionText)}</p>
        </div>
      )}
      <div className={classes.content}>
        {!subscriptions || subscriptions.entities.length === 0 || tabState === TabState.Empty ? (
          <EmptyState ppInstalled={ppInstalled} />
        ) : (
          <>
            <InfiniteScrollProvider>
              <SubscriptionsListBass subscriptions={subscriptions.entities} />
            </InfiniteScrollProvider>
            <CancelConfirmModal />
            {isUpmModalOpened && <UpmModal />}
            {isPayNowModalOpened && <PayNowModal />}
            <Toast />
          </>
        )}
      </div>
    </div>
  );
};

const mapRuntimeToProps = (state: RootState, _: WidgetWrapperProps, __: any) => ({
  subscriptions: state.subscriptions,
  isUpmModalOpened: state.upmModal.isOpen,
  isPayNowModalOpened: state.payNowModal.isOpen,
});

export const Widget = connect(mapRuntimeToProps)(WidgetComponent);
